"use client"

import { Box } from "@/components/ui/layout/Box"
import { useTranslation } from "@/i18n"
import { useTheme } from "@mui/material/styles"
import {
  MenuButtonAddTable,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonStrikethrough,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from "mui-tiptap"
import type { ReactNode } from "react"
import type { RichTextEditorVariant } from "."
import { blobToDataUrl } from "../data"
import MenuButtonSuggestion from "./MenuButtonSuggestion"
import de from "./messages/de.json"
import type { MentionSuggestion } from "./suggestions"
import MenuButtonAiInstruction from "./MenuButtonAiInstruction"

interface RenderControlsProps {
  variant: RichTextEditorVariant
  suggestions?: MentionSuggestion[]
  hideUndoRedo?: boolean
  hideTable?: boolean
  hideTextAlign?: boolean
  hideImage?: boolean
  aiInstruction?: boolean
  slots?: {
    start?: ReactNode
    end?: ReactNode
  }
}

export const renderControls = (
  {
    variant = "document",
    suggestions = [],
    hideUndoRedo = false,
    hideTable = false,
    hideTextAlign = false,
    hideImage = false,
    aiInstruction = false,
    slots,
  }: RenderControlsProps = {
    variant: "document",
  },
) => {
  const theme = useTheme()
  const { t } = useTranslation(de)

  return (
    <MenuControlsContainer>
      {slots?.start}
      {variant === "document" && (
        <>
          <MenuSelectHeading />
          <MenuDivider />
        </>
      )}
      <MenuButtonEditLink />
      <MenuDivider />
      <MenuButtonBold />
      <MenuButtonItalic />
      <MenuButtonUnderline />
      {variant === "document" && <MenuButtonStrikethrough />}
      {variant === "document" && (
        <>
          <MenuDivider />
          <MenuButtonTextColor
            defaultTextColor={theme.palette.common.black}
            swatchColors={[
              { value: "#000000", label: t("swatchColors.black.label") },
              { value: "#595959", label: t("swatchColors.darkGrey.label") },
              { value: "#ff0000", label: t("swatchColors.red.label") },
              { value: "#ff9900", label: t("swatchColors.orange.label") },
              { value: "#ffff00", label: t("swatchColors.yellow.label") },
              { value: "#00d000", label: t("swatchColors.green.label") },
              { value: "#0000ff", label: t("swatchColors.blue.label") },
            ]}
            PopperProps={{
              className: "z-[1300]",
              style: {
                zIndex: theme.zIndex.modal + 1,
              },
            }}
          />
          {/**
           * Highlight color is not yet supported by our HTML 2 PDF renderer,
           * so we'll hide it for now.
           */}
          {/* <MenuButtonHighlightColor
            className="z-[1300]"
            swatchColors={[
              { value: "#595959", label: t("swatchColors.darkGrey.label") },
              { value: "#dddddd", label: t("swatchColors.lightGrey.label") },
              { value: "#ffa6a6", label: t("swatchColors.lightRed.label") },
              { value: "#ffd699", label: t("swatchColors.lightOrange.label") },
              // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
              { value: "#ffff00", label: t("swatchColors.yellow.label") },
              { value: "#99cc99", label: t("swatchColors.lightGreen.label") },
              { value: "#90c6ff", label: t("swatchColors.lightBlue.label") },
              { value: "#8085e9", label: t("swatchColors.lightPurple.label") },
            ]}
            PopperProps={{
              className: "z-[1300]",
              style: {
                zIndex: theme.zIndex.modal + 1,
              },
            }}
          /> */}
        </>
      )}
      <MenuDivider />
      <MenuButtonBulletedList />
      <MenuButtonOrderedList />
      {!hideTextAlign && (
        <>
          <MenuDivider />
          <MenuSelectTextAlign />
        </>
      )}
      {variant === "document" && !hideImage && (
        <>
          <MenuDivider />
          <MenuButtonImageUpload
            onUploadFiles={async (files) =>
              // we don't use a seperate blob store, yet to upload the files
              // to, so we'll instead convert each one to bas64 data urls
              // directly into the editor content, though that can make the
              // editor content very large.
              await Promise.all(
                files.map(async (file) => ({
                  src: await blobToDataUrl(file),
                  alt: file.name,
                })),
              )
            }
          />
        </>
      )}
      {(variant === "document" || !hideTable) && (
        <>
          <MenuDivider />
          {variant === "document" && <MenuButtonHorizontalRule />}
          {!hideTable && <MenuButtonAddTable />}
        </>
      )}
      {!hideUndoRedo && (
        <>
          <MenuDivider />
          <MenuButtonUndo />
          <MenuButtonRedo />
        </>
      )}
      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {typeof window !== "undefined" && isTouchDevice() && (
        <>
          <MenuButtonIndent />
          <MenuButtonUnindent />
        </>
      )}
      {suggestions.length > 0 && (
        <>
          <MenuDivider />
          <MenuButtonSuggestion suggestions={suggestions} />
        </>
      )}
      {aiInstruction && <MenuButtonAiInstruction />}
      <Box flexGrow={1} />
      {slots?.end}
    </MenuControlsContainer>
  )
}
