"use client"

import de from "@/app/_messages/de.json"
import { useTranslation } from "@/i18n"
import { mdiCreation } from "@mdi/js"
import Icon from "@mdi/react"
import {
  MenuButton,
  type MenuButtonProps,
  useRichTextEditorContext,
} from "mui-tiptap"

export type MenuButtonAiInstructionProps = Partial<MenuButtonProps> & {}

export default function MenuButtonAiInstruction(
  props: MenuButtonAiInstructionProps,
) {
  const { t } = useTranslation(de)
  const editor = useRichTextEditorContext()

  const handleCommand = () => {
    editor?.commands.insertContent([
      {
        type: "aiInstruction",
        attrs: props,
      },
    ])
  }

  return (
    <>
      <MenuButton
        tooltipLabel={t("rte.extension.aiInstruction.tooltip")}
        tooltipShortcutKeys={[">"]}
        IconComponent={AiInstructionIcon}
        disabled={!editor?.isEditable}
        onClick={handleCommand}
        {...props}
      />
    </>
  )
}

function AiInstructionIcon() {
  return <Icon path={mdiCreation} size={0.833 /* 20px */} />
}
