"use client"

import { LinkBubbleMenu, TableBubbleMenu } from "mui-tiptap"
import { useRef } from "react"
import type { RichTextEditorVariant } from "."

interface RenderMenusOptions {
  variant: RichTextEditorVariant
}

export const renderMenus = (
  options: RenderMenusOptions = { variant: "document" },
) => {
  const linkMenuPaperRef = useRef<HTMLDivElement>(null)
  const tableMenuPaperRef = useRef<HTMLDivElement>(null)

  // add z index to paper el of ref
  if (linkMenuPaperRef.current?.parentElement) {
    linkMenuPaperRef.current.parentElement.style.zIndex = "1400"
  }
  if (tableMenuPaperRef.current?.parentElement) {
    tableMenuPaperRef.current.parentElement.style.zIndex = "1400"
  }

  return (
    <>
      <LinkBubbleMenu
        classes={{
          root: "z-[1400]",
        }}
        PaperProps={{
          ref: linkMenuPaperRef,
        }}
        // disablePortal
      />
      <TableBubbleMenu
        classes={{
          root: "z-[1400]",
        }}
        PaperProps={{
          ref: tableMenuPaperRef,
        }}
        disablePortal
      />
    </>
  )
}
